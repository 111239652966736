$(function() {

    const mobileMenuButton = $('.mobile-dropdown-button');
    const dropdownMenu = $('.mobile-dropdown');

    const mobileFixedMenuButton = $('.mobile-fixed-dropdown-button');
    const dropdownMenuFixed = $('.mobile-dropdown-fixed');

    let mobileMenuClosed = true;
    let mobileFixedClosed = true;

    mobileMenuButton.on('click', function() {
        if(mobileMenuClosed){
            openMenu();
        } else {
            closeMenu();
        }
    });

    mobileFixedMenuButton.on('click', function() {
        if(mobileFixedClosed){
            openFixedMenu();
        } else {
            closeFixedMenu();
        }
    });

    const fixedNav = document.querySelector('.fixed-nav');
    let scrollPos = 0;

    window.isAnimating = false;
    window.addEventListener('scroll', (e) => {
        if (window.isAnimating) {
            return;
        }
        if(window.innerWidth < 992) {
            handleFixedNavOnMobile();
        } else {
            handleFixedNavOnDesktop();
        }
    });

    const handleFixedNavOnMobile = () => {
        if ((document.body.getBoundingClientRect()).top > scrollPos){
            fixedNav.style.top = "0px"
        } else {
            fixedNav.style.top = "-80px"
            closeFixedMenu();
        }

        if(Math.abs(scrollPos) < 600) {
            fixedNav.style.top = "-80px"
            closeFixedMenu();
        }

        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    const handleFixedNavOnDesktop = () => {
        if ((document.body.getBoundingClientRect()).top > scrollPos){
		    fixedNav.style.top = "0px"
            fixedNav.style.visibility = "visible";
        } else {
            fixedNav.style.top = "-134px"
            fixedNav.style.visibility = "hidden";
        }

        if(Math.abs(scrollPos) < 450) {
            fixedNav.style.top = "-134px"
            fixedNav.style.visibility = "hidden";
        }
        
        scrollPos = (document.body.getBoundingClientRect()).top;
    }

    let bodyClass = $("body").attr('class').split(' ')[0];
    bodyClass = bodyClass + "-nav"

    for (const active of Array.from(document.querySelectorAll(`nav a[class="${bodyClass}"]`))) {
        active.parentElement.classList.add("active");
    }

    var setCookieNoticeAcceptance = function () {
        document.cookie = "MoreThanHDCookieAccepted=true; max-age=31536000; path=/";
    }

    var cookieNoticeAccepted = function(name) {
        var dc      = document.cookie;
        var prefix  = name + "=";
        var begin   = dc.indexOf("; " + prefix);

        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) {
                return null
            }
        } else {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);

            if (end == -1) {
                end = dc.length;
            }
        }

        // because unescape has been deprecated, replaced with decodeURI
        // return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    }

    $(document).on("click", "#cookie-controls", function(event) {
        event.preventDefault();
        setCookieNoticeAcceptance();
        $('body').removeClass("show-cookie-notification");
        return false;
    });

    if (cookieNoticeAccepted("MoreThanHDCookieAccepted")) {
        $('body').removeClass("show-cookie-notification");
    } else {
        $('body').addClass("show-cookie-notification");
    }

    $(".external-link").on("click", function(e) {
        e.preventDefault();
        var $this = $(this);

        $(".overlay").fadeIn();
        $("#external-link-modal").fadeIn();

        $("#external-link-modal .cta-leave-site").attr("href", $this.attr("href"));
    });
    $(".third-party-link").on("click", function(e) {
        e.preventDefault();
        var $this = $(this);
        console.log($this.attr("href"));

        $('.overlay').fadeIn();
        $("#third-party-link-modal").fadeIn();

        $("#third-party-link-modal .cta-leave-site").attr("href", $this.attr("href"));
    });

    $(".cta-stay-here").on("click", function(e) {
        e.preventDefault();
        $(".overlay").fadeOut();
        $(".modal").fadeOut();
    });
    $(".cta-leave-site").on("click", function(e) {
        // e.preventDefault();
        // window.open(e.target.href, "_blank");
        $(".overlay").fadeOut();
        $(".modal").fadeOut();
    });

    const openMenu = () => {
        dropdownMenu.show();
        mobileMenuButton.html("CLOSE");
        mobileMenuClosed = false;
    }
    const closeMenu = () => {
        dropdownMenu.hide();
        mobileMenuButton.html("MENU");
        mobileMenuClosed = true;
    }

    const openFixedMenu = () => {
        dropdownMenuFixed.show();
        mobileFixedMenuButton.html("CLOSE");
        mobileFixedClosed = false;
    }
    const closeFixedMenu = () => {
        dropdownMenuFixed.hide();
        mobileFixedMenuButton.html("MENU");
        mobileFixedClosed = true;
    }
    window.scrollToAnimated = (top) => {
        window.isAnimating = true;
        if (window.innerWidth < 992) {
            fixedNav.style.top = "-80px";
        }
        else {
            fixedNav.style.top = "-134px";
        }
        closeFixedMenu();
        $("html, body").animate({
            scrollTop: top,
        }, {
            duration: 0,
            queue: false,
            always: () => {
                setTimeout(() => {
                    window.isAnimating = false;
                }, 600);
            }
        });
    }

    $(window).on("resize", function() {
        let screenWidth = window.innerWidth;
        if(screenWidth > 992) {
            closeMenu();
        }
    });

    const removeThankyouPageUnderline = ()=>{
        try {
            
            let currentPage = window.location.pathname;
                if (currentPage === "/thank-you/") {
                    let activeLi = document.querySelector('.nav-container .desktop-nav.lg-desktop-show ul li.active');
                if (activeLi) {
                    activeLi.classList.remove('active');
                }
            }
        } catch (error) {
        }
    }
    removeThankyouPageUnderline();
});










